import React from 'react';
import { graphql } from 'gatsby';
import cx from 'classnames';

import * as styles from './styles/page.module.scss';
import withMeta from '../hocs/withMeta';
import { PageElements } from '../elements';
import { Image } from '../components';
import { IPageDetails, IPageContext } from '../interfaces';
import Landing from '../components/Landing';

interface IProps {
  data: {
    contentfulPage: IPageDetails;
  };
  pageContext: IPageContext;
}

const Page = (props: IProps) => {
  const { data, pageContext } = props;
  const {
    title,
    heroImage,
    pageElements,
    imageOverlay,
    isLandingPageTemplate,
    carousel,
    recommendedTours,
  } = data.contentfulPage;
  const light = pageContext.navColor === 'light';
  if (isLandingPageTemplate) {
    return (
      <Landing
        {...{
          title,
          heroImage,
          pageElements,
          pageContext,
          carousel,
          recommendedTours,
        }}
      />
    );
  }
  return (
    <React.Fragment>
      <Image fluidImage={heroImage} className={styles.heroImage} />
      <div
        className={styles.overlay}
        style={{
          backgroundColor: light ? 'black' : 'white',
          display: imageOverlay ? 'block' : 'none',
        }}
      />
      <div className='centered-content'>
        <h1 className={cx(styles.title, { [styles.lightTitle]: light })}>
          {title}
        </h1>
      </div>
      <PageElements
        elements={pageElements}
        pageContext={pageContext}
        carousel={carousel}
      />
    </React.Fragment>
  );
};

export const query = graphql`
  query PageQuery($slug: String!, $locale: String!) {
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...PageDetails
    }
  }
`;

export default withMeta(Page);
