import React from 'react';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';
import { Image, TextParser } from '../../components';
import * as styles from './index.module.scss';
import {
  IImage,
  IPageContext,
  IPageElement,
  IPageTours,
  IPageWysiwyg,
  ITourItem,
  IReducers,
} from '../../interfaces';
import { PageTours } from '../../elements';
import { RecommendedTours } from '../../components';

interface IExternalProps {
  title: string;
  heroImage?: IImage;
  pageElements?: IPageElement[];
  pageContext: IPageContext;
  carousel?: boolean;
  recommendedTours?: ITourItem[];
  locale: string;
}

interface IProps extends IExternalProps {
  localeData: {
    node_locale: string;
    recommendedToursTitle: string;
    seeMoreButton: string;
  };
}

const Landing = ({
  title,
  heroImage,
  pageElements,
  pageContext,
  recommendedTours,
  carousel,
  localeData,
}: IProps) => {
  return (
    <div>
      <Image fluidImage={heroImage} className={styles.heroImage} />
      <div className={styles.titleWrapper}>
        <div className='centered-content'>
          <div className='columns'>
            <div className='column is-10-mobile is-7'>
              <h1 className={styles.title}>{title}</h1>
            </div>
          </div>
        </div>
      </div>
      {pageElements &&
        pageElements.map((elem, index) => {
          switch (elem.__typename) {
            case 'ContentfulPageWysiwyg': {
              const pageWysiwyg = elem as IPageWysiwyg;
              const grid = recommendedTours ? 'is-5' : 'is-12';
              return (
                <div className='centered-content vertical-margin' key={index}>
                  <div className='columns'>
                    <div className={`column ${grid}`}>
                      <TextParser source={pageWysiwyg.text.text} />
                    </div>
                    <RecommendedTours
                      recommendedTours={recommendedTours}
                      localeData={localeData}
                      pageContext={pageContext}
                    />
                  </div>
                </div>
              );
            }
            case 'ContentfulPageTours': {
              const pageTours = elem as IPageTours;
              return (
                <PageTours
                  key={elem.id}
                  pageContext={pageContext}
                  pageTours={pageTours}
                  carousel={carousel}
                />
              );
            }
          }
          return;
        })}
    </div>
  );
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({
    title,
    heroImage,
    pageElements,
    pageContext,
    recommendedTours,
    carousel,
    locale,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query LandingQuery2 {
          allContentfulTranslationsVarious {
            edges {
              node {
                recommendedToursTitle
                seeMoreButton
                node_locale
              }
            }
          }
        }
      `}
      render={(data) => (
        <Landing
          localeData={
            data.allContentfulTranslationsVarious.edges.filter(
              (node: { node: { node_locale: string } }) => {
                return node.node.node_locale === locale;
              }
            )[0].node
          }
          title={title}
          heroImage={heroImage}
          pageElements={pageElements}
          pageContext={pageContext}
          recommendedTours={recommendedTours}
          carousel={carousel}
          locale={locale}
        />
      )}
    />
  )
);
